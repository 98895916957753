.legend{
    position: absolute;
    bottom: 100px;
    left: 50%;
    margin-left: -45%;
    width: 50%;
    border-radius: 10px;
    background: #043902;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: .6;
    transition: opacity .35s ease-in-out;
    text-align: left;
    font-size: large;
}
.legend b{
    font-size: x-large;
}
.legend:hover{
    opacity: 1;
}