.header {
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: space-between;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
    align-items: center;
    text-decoration: none;
}

.header>div:first-child {
    align-items: center;
    margin-left: 20px;
    display: flex;
    flex-direction: row;

}

.header>div:first-child>p {
    font-size: x-large;
    margin-left: 20px;
    color: #28B463;
    font-weight: bold;
}

.header>ul {
    list-style: none;
    height: 30px;
    line-height: 30px;
    z-index: 100;
}

.header>ul>li {
    display: inline;
    float: left;
    margin: 0 20px 0 20px;
    width: 120px;
    cursor: pointer;
}

.header>ul>li:hover {
    font-weight: bolder;
}

.header>ul>li {
    font-size: large;
    margin-left: 20px;
    color: #28B463;
    font-weight: bold;
}

.header>ul>li a {
    text-decoration: none;
    color: inherit;
}

.submenu {
    position: relative;
    margin-top: 10px;
}

.submenu>ul {
    text-align: left;
    width: 120px;
    list-style: none;
}

.submenu>ul>li {
    text-align: left;
    display: block;
    float: left;
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-left: -30px;
    background-color: white;
}

.submenu>ul>li a {
    width: 100%;
    height: 100%;
    display: inline-block;
}

.header>ul>li>div>ul>li:hover {
    background: #28B463;
    color: white;
}

/* Mobile display */

.hamburger {
    display: flex;
    flex-direction: column;
    position: absolute;
    top:0;
    right: 0;
    left: 0;
    align-items: flex-end;
    width: 100%;
}

.hamburger_open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top:0;
    right: 0;
    left: 0;
    align-items: flex-end;
    width: 100%;
    background-color: #28B463;;
}

.menu_toggle {
    font-size: 36px;
    cursor: pointer;
    margin-top: 25px;
    margin-right: 30px;
  }
  .menu_container{
    width: 100%;
  }
  .menu {
    list-style-type: none;
    display: block;
    width: 100%;
    text-align: left;
  }
  
  .menu li {
    margin: 0 10px;
    display: block;
    width: 100%;
    width: 100%;
    line-height: 30px;
    font-size: larger;
    font-weight: 600;
  }
  
  .menu a {
    color: whitesmoke;
    text-decoration: none;
  }