.container{
    background-color: white;
    margin: 10px;
    margin-top: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}

.container > div {
    text-align: justify;
    padding: 20px;
    height: fit-content;
    color: rgb(86, 85, 85);
    font-size: small;
}

.container > div:first-child > p {
   border-right: 1px solid rgb(197, 196, 196);
   padding-right: 20px;
}

.container > div:nth-child(2) > p {
    border-right: 1px solid rgb(197, 196, 196);
    padding-right: 20px;
 }

 /* Mobile Display */

 .container_mobile{
    background-color: white;
    margin: 10px;
    margin-top: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
}

.container_mobile > div {
    text-align: justify;
    height: fit-content;
    color: rgb(86, 85, 85);
    font-size: small;
    padding-left: 20px;
}
