.products{
    display: flex;
    justify-content: space-around;
}
.products > div{
    border-radius: 50%;
    height: 150px;
    width: 150px;
    color: rgb(62, 27, 27);
    border: 1px solid green;
    background-color: white;
    -webkit-box-shadow: 0 0 10px #fff;
    box-shadow: 0 0 10px #fff;
    margin: 20px;
}

.products > div:hover{
    color: black;
    border: 1px solid rgb(11, 7, 237);
    -webkit-box-shadow: 0 0 20px #fff;
    box-shadow: 0 0 10px #0658f0;
}

.products > div  img {
    border-radius: 50%;
    height: 150px;
    width: 150px;
}


.products > div > a > img:hover {
    opacity: 0.2;
}

.products > div > p {
   margin-top: -90px;
   font-size: larger;
   font-weight: bold;
 }

/* MOBILE DISPLAY */

.products_mobile{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.products_mobile > div{
    border-radius: 50%;
    height: 100px;
    width: 100px;
    color: rgb(62, 27, 27);
    border: 1px solid green;
    background-color: white;
    -webkit-box-shadow: 0 0 10px #fff;
    box-shadow: 0 0 10px #fff;
    margin: 20px;
}

.products_mobile > div:hover{
    color: black;
    border: 1px solid rgb(11, 7, 237);
    -webkit-box-shadow: 0 0 20px #fff;
    box-shadow: 0 0 10px #0658f0;
}

.products_mobile > div  img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}


.products_mobile > div > img:hover {
    opacity: 0.2;
}

.products_mobile > div > p {
   margin-top: -65px;
   font-size: medium;
   font-weight: bold;
 }