.about_us_div{
    background-image: url("../../../img/about_us_bg.jpg");
    height: 412px;
    display: flex;
    justify-content: space-around;
    margin: 0 10px 0 10px;
    border-radius: 5px;
}

.about_us_div > div:first-child >img{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}


.about_us_div div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
}


.about_us_div div > p {
    font-size: large;
    color: white;
    width: 75%;
    text-align: justify;
    max-height: 99%;
}

.about_us_div div > p > b{
 font-size: xx-large;
 color: white;
}

/* MOBILE DISPLAY */
.about_us_mobile{
    background-image: url("../../../img/about_us_bg.jpg");
    height: 412px;
    display: flex;
    justify-content: space-around;
    margin: 0 10px 0 10px;
    border-radius: 5px;
}

.about_us_mobile > div:first-child >img{
   display: none;
}


.about_us_mobile div:last-child {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    height: 412px;
    overflow-y: scroll;
}


.about_us_mobile div > p {
    font-size: medium;
    color: white;
    margin: 20px 20px 0 20px;
    text-align: justify;
    max-height: 99%;
}

.about_us_mobile div > p > b{
 font-size: x-large;
 color: white;
}
