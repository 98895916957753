.container{
    display: flex;
    flex-direction: column;
}
.container > div{
    background: white;
    margin: 0 10px 0 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}
.scrollable_text{
    height: 300px;
    overflow-y: scroll;
}

.container > div:nth-child(n+1){
    margin: 10px 10px 0 10px;
}
.container > div p {
    color: rgb(86, 85, 85);
    margin: 10px;
    text-align: justify;
}

.container > div p b{
    font-size: large;
}

.image{
    height: 300px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.inner_col_containers{
    flex-direction: column!important;
}


/* Mobile Display */

.container_mobile{
    display: flex;
    flex-direction: column;
}
.container_mobile > div{
    background: white;
    margin: 0 10px 0 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}
.mobile_scrollable_text{
    height: 300px;
    overflow-y: scroll;
}

.container_mobile > div:nth-child(n+1){
    margin: 10px 10px 0 10px;
}
.container_mobile > div p {
    color: rgb(86, 85, 85);
    margin: 10px;
    text-align: justify;
}

.container_mobile > div p b{
    font-size: large;
}

.mobile_image{
    height: 300px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.container_mobile{
    flex-direction: column!important;
}